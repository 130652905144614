<template>
  <div class="teacher">
    <div class="title_search">
      <div class="title_search_one">
        <el-select v-model="form.evaluation_id" placeholder="筛选测评名称" style="flex:1;margin-right: 10rem;" size="small" @enter="seek">
          <el-option v-for="item in evaluationNameArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-select v-model="form.school_id" placeholder="筛选校区" style="flex:1;margin-right: 10rem;" size="small" @change="onSchoole" @enter="seek">
          <el-option v-for="item in schoolArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-select v-model="form.grade_id" placeholder="筛选年级" style="flex:1;margin-right: 10rem;" size="small" @change="onGrade" @enter="seek">
          <el-option v-for="item in gradeArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-select v-model="form.class_id" placeholder="筛选班级" style="flex:1;margin-right: 10rem;" size="small" @change="onClass" @enter="seek">
          <el-option v-for="item in classArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <div>
          <el-button type="primary" size="small" @click="seek">查找</el-button>
          <el-button size="small" @click="reset">重置</el-button>
        </div>
      </div>
      <div class="title_search_tow" v-if="data.length!==0">
        <div class="left_str">危机预警</div>
        <div class="rigth_str" @click="onReadMore" v-if="dataTable.length">查看更多</div>
      </div>
      <div class="title_search_excl" style="padding: 0 36rem 24rem 36rem" v-if="show">
        <el-table :data="dataTable" style="width: 100%" v-if="dataTable.length">
          <el-table-column prop="student" label="姓名"></el-table-column>
          <el-table-column prop="score" label="总分"></el-table-column>
          <el-table-column prop="positive" label="阳性项目数"></el-table-column>
          <el-table-column prop="average" label="总均分"></el-table-column>
          <el-table-column prop="school" label="校区"></el-table-column>
          <el-table-column prop="grade" label="年级"></el-table-column>
          <el-table-column prop="class" label="班级"></el-table-column>
          <el-table-column prop="address" label="详情" fixed="right" width="60">
            <template slot-scope="scope">
              <div style="color:#64abfb" @click="onGo(scope.row)">查看</div>
            </template>
          </el-table-column>
        </el-table>
        <t-result type="empty" v-else :text="data.length===0 ? '暂无数据' :'太好了！没有达到预警值的学生'"></t-result>
      </div>
    </div>
    <div v-if="show">
      <div v-if="data.length">
        <div class="information_item" v-for="(item, index) in data">
          <div class="information_item_left">
            <div class="information_item_left_img">{{ item.name }}</div>
            <div class="information_item_left_echart">
              <div id="main" style="width:100%;height:100%"></div>
            </div>
          </div>
          <div class="information_item_rigth" v-if="show">
            <el-table :data="data[index].data.scl" v-if="tableData && tableData.length > 0" style="width: 100%" size="small">
              <el-table-column label="测试结果">
                <template slot-scope="state_text">
                  <p
                      :style="{ color: state_text.row.state == '1' ? '#06C270' : state_text.row.state == '2' ? '#F6AD3C' : '#F76560' }">
                    {{ state_text.row.state_text }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="student" label="姓名"></el-table-column>
              <el-table-column prop="score" label="得分"></el-table-column>
              <el-table-column prop="school" label="校区"></el-table-column>
              <el-table-column prop="class" label="班级"></el-table-column>
              <el-table-column label="详情">
                <template slot-scope="scope">
                  <div style="color:#64abfb" @click="onExamine(scope.row)">查看</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
	_config:{"route":{"path":"details","meta":{"title":"数据看板"}}},
  data () {
    return {
      value: '',
      options: [],
      tableData: [],
      data: [],
      dataTable: [],
      form: {
        // evaluation_id:'',
        // school_id:'',
        // grade_id:'',
        // class_id:''
      },
      evaluationNameArr: [],
      schoolArr: [],
      gradeArr: [],
      classArr: [],
      show:true
    }
  },
  methods: {
    seek () {
      this.show = false
      this.$_axios2.get('api/evaluation/statistics/scl', { params: { ...this.form } }).then(res => {
        this.data = res.data.data;
        this.$forceUpdate()
        this.acquire()
        this.$_axios2.get('api/evaluation/statistics/crisis', { params: { ...this.form } }).then(res => {
          this.dataTable = res.data.data;
        })
      })
      setTimeout(()=>{
        this.show = true
        this.$forceUpdate()
      },0)
    },
    acquire () {
      if (this.data.length > 0) {
        setTimeout(() => {
          var chartDom = document.querySelectorAll('#main');
          var myChart = []
          var option = []
          this.data.forEach((item, index) => {
            this.tableData.push(item.data.scl)
            myChart.push((echarts.init(chartDom[index])))
            option.push({
              color: ['#f76560', '#f6ad3c', '#06c270'],
              tooltip: {
                formatter: '{d}%'
              },
              series: [
                {
                  type: 'pie',
                  radius: '65%',
                  center: ['50%', '50%'],
                  selectedMode: 'single',
                  data: [
                    { value: item.data.pie.severe },
                    { value: item.data.pie.light },
                    { value: item.data.pie.asymptomatic },
                  ],
                  labelLine: {
                    show: false
                  },
                }
              ]
            })
          })
          myChart.forEach((item, index) => {
            option && myChart[index].setOption(option[index]);
          })
          }, 100);
        }
    },
    reset () {
      this.form = {};
      this.commonInterface()
      setTimeout(() => {
        this.seek()
      }, 0);
    },
    onClass(val){
      this.$forceUpdate();
    },
    onSchoole (val) {
      this.form.grade_id = ''
      this.form.class_id = ''
      this.gradeArr = []
      this.classArr = []
      this.$_axios2.get('api/evaluation/result/get-range?school_id=' + val).then(res=>{
        this.gradeArr = JSON.parse(JSON.stringify(res.data.data.grade).replace(/id/g, 'value').replace(/name/g, 'label'));
        this.classArr = JSON.parse(JSON.stringify(res.data.data.class).replace(/id/g, 'value').replace(/name/g, 'label'));
      })
    },
    onGrade (val) {
      this.form.class_id = ''
      this.classArr = []
      this.$_axios2.get('api/evaluation/result/get-range?grade_id=' + val).then(res=>{
        this.classArr = JSON.parse(JSON.stringify(res.data.data.class).replace(/id/g, 'value').replace(/name/g, 'label'));
      })
    },
    commonInterface () {
      this.$_axios2.get('api/evaluation/result/get-range').then(res=>{
	      if (res.data.status === 0 ){
		      this.schoolArr = JSON.parse(JSON.stringify(res.data.data.school).replace(/id/g, 'value').replace(/name/g, 'label'));
		      this.gradeArr = JSON.parse(JSON.stringify(res.data.data.grade).replace(/id/g, 'value').replace(/name/g, 'label'));
		      this.classArr = JSON.parse(JSON.stringify(res.data.data.class).replace(/id/g, 'value').replace(/name/g, 'label'));
	      }
      })
    },
    onReadMore () {
      this.$store.commit('setPage', 1)
      this.$router.push({ path: '/moral/task-manage/evaluation-results/list?id=1' })
    },
    onGo (row) {
      this.$router.push({ path: '/moral/task-manage/evaluation-results/examine?id=' + row.id })
    },
    onExamine (row) {
      this.$router.push({ path: '/moral/task-manage/evaluation-results/examine?id=' + row.id })
    }
  },
  created () {
    this.$_axios2.get('api/evaluation/statistics/scl').then(res => {
      this.data = res.data.data;
			setTimeout(()=>{
				this.acquire()
			},10)
    })
    this.$_axios2.get('api/evaluation/statistics/crisis').then(res => { this.dataTable = res.data.data; })
    this.$_axios2.get('api/evaluation/statistics/evaluation').then(res => {
      this.evaluationNameArr = JSON.parse(JSON.stringify(res.data.data).replace(/id/g, 'value').replace(/name/g, 'label'));
    })
    this.commonInterface()
  },
}
</script>

<style scoped lang="scss">
	.teacher {
		background: #f7f7f7 !important;

		.title_search {
			width: 99% !important;
			min-height: 100rem;
			//width: 654rem;
			background: #ffffff;
			opacity: 1;
			border-radius: 11rem;
			padding-bottom: 14rem;

			.title_search_one {
				width: 100%;
				height: 63rem;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0 20rem;
			}

			.title_search_tow {
				width: 100%;
				height: 50rem;
				background: #ffeeeb;
				opacity: 1;
				border-radius: 11rem 11rem 0 0;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0 50rem 0 45rem;

				.left_str {
					font-size: 18rem;
					font-weight: bold;
					color: #f76560;
					opacity: 1;
				}

				.rigth_str {
					font-size: 14rem;
					font-weight: 400;
					line-height: 24rem;
					color: #3491fa;
					opacity: 1;
				}
			}
		}

		.information_item {
			width: 99%;
			height: 340rem;
			background: #ffffff;
			opacity: 1;
			border-radius: 20rem;
			margin-top: 24rem;
			display: flex;

			.information_item_left {
				width: 40%;
				height: 100%;

				.information_item_left_img {
					width: 60%;
					height: 41rem;
					background: url('../../../../assets/img/5期/bg_list.png') no-repeat;
					font-size: 16rem;
					font-weight: 400;
					line-height: 41rem;
					color: #333333;
					opacity: 1;
					padding-left: 47rem;
				}

				.information_item_left_echart {
					width: 100%;
					height: calc(100% - 41rem);
				}
			}

			.information_item_rigth {
				width: 60%;
				min-height: 100rem;
				display: flex;
				margin-top: 41rem;
				margin-right: 48rem;
			}
		}
	}
	::v-deep .el-table th.rem>.cell{
		font-weight: 500;
		font-size: 14rem;
	}
	::v-deep .el-table__cell{
		background: #fff !important;
	}
</style>
